input[type="checkbox"] {
   
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1rem;
  height: 1rem;
  border: 0.01em solid #F18181;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.paymentCheckbox{
   
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1rem;
  height: 1rem;
  border: 0.01em solid #F18181;
  border-radius: 0.15em;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 1em;
  border-radius: 0.15em;
  height: 1em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: #D10A11;
  background-image: url(../../../src/Images/Group\ 850.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0.6rem;

}

.requestedClass input[type="checkbox"] {
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1rem;
  height: 1rem;
  border: 0.01em solid #039511;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.requestedClass input[type="checkbox"]::before {
  pointer-events: none;
  content: "";
  width: 1em;
  border-radius: 0.15em;
  height: 1em;
  transform: scale(0);  
  transition: 120ms transform ease-in-out;
  background-color: #039511;
  background-image: url(../../../src/Images/Group\ 850.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0.6rem;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.partialCheck input[type="checkbox"]::before {
  /* filter: brightness(1.5); */
  content: "";
  width: 1em;
  border-radius: 0.15em;
  height: 1em;
  /* transform: scale(0); */
  transition: 120ms transform ease-in-out;
  background-color: #D10A11 !important;
  background-image: url(../../../src/Images/minus.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;

}
