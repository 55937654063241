.dw-btn {
    text-align: -webkit-center;
    padding-left: 1rem;
}

.height800 {
    height: 800px;
}

/* Home Register */

.main-div {

    width: 100%;
    /* background-image: url(assets/Images/Mask_Group_23_2x.png) !important; */
    /* background: url("https://media.geeksforgeeks.org/wp-content/uploads/rk.png"); */
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
    background-size: cover !important;

}

.banner2 {
    width: 100%;
    /* background-image: url(assets/Images/Group\ 817@2x.png); */
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
    background-size: cover !important;
}



/* Matches Profile */

.red-box-shadow{
    box-shadow: 0px 4px 1px #D10A11;
}


.width71per
{
    width: 71%;
}
.width76per
{
    width: 76%;
}