
.container{
    width: 100%;
    height: 100vh;
}

.dropdown{
     width: 100%;
    user-select: none;
    position: relative;
}

.carret{
    transition: all .2s;
    position: relative;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #F18181;  
   
}

.dropdown .dropdown-btn{
    padding: 6px;
    font-size: 14px;
    background: #fff;
    color: #575556;
    border: 1px solid #F18181;
    font-weight:500;
    display: flex;
    align-items: center;
    /* clip-path: polygon(0 0, 0 84%, 48% 100%, 100% 85%, 100% 0); */
    cursor: pointer;
    justify-content: space-between;
    
}

.dropdown .dropdown-content{
    /* position: absolute;
    top: 110%;
    left: 0; */
    /* padding: 10px; */
    border: 1px solid #F18181;
    background: #fff;
    
    font-weight: 500;
    color: #333;
    width: 100%;
     
}

.dropdown .dropdown-content .dropdown-item{
    padding: 6px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s;
    border-bottom: 1px solid #F18181;
}

.dropdown .dropdown-content .dropdown-item:last-child{
    border-bottom: none;
}

.dropdown .dropdown-content .dropdown-item:hover{
    background: #F18181;
    color: white;
}

