@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "Cabin-Condensed-SemiBold";
  src: url('/src/Components/Font/Cabin_Condensed-SemiBold.ttf') format('truetype');
  /* font-style: normal;
  font-weight: normal; */
}

@font-face {
  font-family: "Cabin-Condensed-Regular";
  src: url('/src/Components/Font/Cabin_Condensed-Regular.ttf') format('truetype');
  /* font-style: normal;
  font-weight: normal; */
}

@font-face {
  font-family: "Cabin-Medium";
  src: url('/src/Components/Font/Cabin-Medium.ttf') format('truetype');
  /* font-style: normal;
  font-weight: normal; */
}

@font-face {
  font-family: "Segoe-semiBold";
  src: url('/src/Components/Font/Segoe/Segoe\ UI.ttf') format('truetype');
  /* font-style: normal;
  font-weight: normal; */
}

@font-face {
  font-family: "Poppins-Regular";
  src: url('/src/Components/Font/Poppins/Poppins-Regular.ttf') format('truetype');
  /* font-style: normal;
  font-weight: normal; */
}

@font-face {
  font-family: "Poppins-Medium";
  src: url('/src/Components/Font/Poppins/Poppins-Medium.ttf') format('truetype');
  /* font-style: normal;
  font-weight: normal; */
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url('/src/Components/Font/Poppins/Poppins-SemiBold.ttf') format('truetype');
  /* font-style: normal;
  font-weight: normal; */
}

@font-face {
  font-family: "Poppins-Bold";
  src: url('/src/Components/Font/Poppins/Poppins-Bold.ttf') format('truetype');
  /* font-style: normal;
  font-weight: normal; */
}


@font-face {
  font-family: "Tamil-Light";
  src: url('/src/Components/Font/tamil/NotoSansTamil-Light.ttf') format('truetype');
}

@font-face {
  font-family: "Tamil-Regular";
  src: url('/src/Components/Font/tamil/NotoSansTamil-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "Tamil-Medium";
  src: url('/src/Components/Font/tamil/NotoSansTamil-Medium.ttf') format('truetype');
}

@font-face {
  font-family: "Tamil-SemiBold";
  src: url('/src/Components/Font/tamil/NotoSansTamil-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: "Tamil-Bold";
  src: url('/src/Components/Font/tamil/NotoSansTamil-Bold.ttf') format('truetype');
}

@font-face {
  font-family: "Tamil-ExtraBold";
  src: url('/src/Components/Font/tamil/NotoSansTamil-ExtraBold.ttf') format('truetype');
}

@layer components {
  #websitecontent p:first-child {
    @apply text-black font-bold text-[12px] md:text-[14px] lg:text-[18px] xl:text-[18px];
  }

  #websitecontent p:last-child {
    @apply text-black text-[12px] md:text-[14px] lg:text-[18px] xl:text-[18px]
  }
}

#websitecontent {
  display: grid;
  gap: 1rem;
}

.selectWithArrow {
  /* position: relative; */
  display: inline-block;
}

.selectWithArrow select {
  /* display: block; */
  min-height: 35px;
  background: #ffffff;
  border-radius: 3px;
  outline: none;
  padding: 0 20px 0 10px;
  margin-top: 5px;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}

.selectWithArrow::after {
  content: '';
  border-width: 5px;
  border-style: solid;
  border-color: transparent;
  border-top-color: #8F8F8F;
  display: inline-block;
  border-radius: 2px;
  position: absolute;
  right: 10px;
  bottom: 14px;
}

.select {
  position: relative;
  display: inline-block;
}

.select select {
  display: block;
  min-height: 35px;
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid #8f8f8f;
  outline: none;
  padding: 0 20px 0 10px;
  margin-top: 5px;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}

.select::after {
  content: '';
  border-width: 5px;
  border-style: solid;
  border-color: transparent;
  border-top-color: #8F8F8F;
  display: inline-block;
  border-radius: 2px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.custom-select {
  position: relative;
  display: inline-block;
}

.custom-select select {
  display: block;
  min-height: 35px;
  background: #ffffff;
  border-radius: 3px;
  border: 2px solid #F18181;
  outline: none;
  padding: 0 20px 0 10px;
  margin-top: 5px;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}

.custom-select::after {
  content: '';
  border-width: 5px;
  border-style: solid;
  border-color: transparent;
  border-top-color: #F18181;
  display: inline-block;
  border-radius: 2px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.custom-select .selector-options {
  list-style: none;
  padding: 0px 0;
  margin: 0;
  background: white;
  color: #575556;
  border-radius: 4px;
  z-index: 1;
  width: 100%;
  position: absolute;
  top: 109%;
}

.custom-select .selector-options li {
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;
  border: 1px solid #F18181;
  transition: background 0.3s ease;
}

.selector-options li:hover {
  background: #F18181;
  color: white
}

.carret {
  content: '';
  border-width: 5px;
  border-style: solid;
  border-color: transparent;
  border-top-color: #F18181;
  display: inline-block;
  border-radius: 2px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

/* radio button  */
.checkbox:checked {
  border: none;
}

.rupeefont {
  font-family: sans-serif !important;
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* ================================ */

.rightSideSlider .slick-prev {
  left: 10rem !important;
  display: block;
  top: 24.5rem !important;
  z-index: 9;
}

.rightSideSlider .slick-next {
  right: 10rem !important;
  display: block;
  top: 24.5rem !important;
  z-index: 9;
}

.rightSideSlider .slick-dots li {
  margin: 0 -5px !important;
}

.rightSideSlider .slick-dots {
  bottom: -39px !important;
}

.rightSideSimilarSlider .slick-prev {
  left: 10rem !important;
  display: block;
  top: 16.5rem !important;
  z-index: 9;
}

.rightSideSimilarSlider .slick-next {
  right: 10rem !important;
  display: block;
  top: 16.5rem !important;
  z-index: 9;
}

.slick-track {
  display: flex;
}

.dashboardCarosel .slick-list {
  overflow-x: auto;
}

.message-text {
  display: -webkit-box;
  max-width: 265px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inbox-text {
  display: -webkit-box;
  max-width: 124px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* @media only screen and (max-width: 600px) {

  .inbox-text{
display: -webkit-box;
    max-width: 124px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

} */

@media only screen and (max-width: 900px) {

  .inbox-text {
    display: -webkit-box;
    max-width: 124px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}

@media only screen and (max-width: 600px) {

  .inbox-text {
    display: -webkit-box;
    max-width: 116px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}

.message-content {

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.ant-select-selector {
  height: 5rem !important;
  overflow: auto;
}

/* =========select dropdown arrow ============== */

select {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 3px;
  margin: 0px 0;

}


/* .ant-select.new{
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='130' height='130' fill='%23F18181'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat !important;
    background-size: 15px;
    background-position: calc(100% - 5px) 14px;
    background-repeat: no-repeat;
    width: 100% !important;
    height: 100% !important;

} */


select.one {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='130' height='130' fill='%23F18181'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
  background-color: white;
}

select.one {
  background-size: 15px;
  background-position: calc(100% - 5px) 14px;
  background-repeat: no-repeat;
}

select.basicInfo {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='130' height='130' fill='%23000000'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
  background-color: white;
}

select.basicInfo {
  background-size: 15px;
  background-position: calc(100% - 5px) 14px;
  background-repeat: no-repeat;
}

select option:hover,
select option:focus,
select option:active {
  background: linear-gradient(#000000, #000000);
  background-color: #000000 !important;
  /* for IE */
  color: #ffed00 !important;
}

/* /.../ */

select.profileOne {
  background-color: #e9e9e9;
  background-size: 15px;
  background-position: calc(100% - 5px) 14px;
  background-repeat: no-repeat;
}


select.profileone {
  background: url("Images/Group 56.svg") no-repeat;
  background-color: white;
}

select.profileone {
  background-color: #e9e9e9;
  background-size: 15px;
  background-position: calc(100% - 12px) 12px !important;
  background-repeat: no-repeat;
}


/* =============placeholder color================== */
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #575556 !important;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #575556;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #575556;
}


.ant-select-selector {
  width: 100%;
  height: 40px !important;
  padding: 5px 11px !important;
}

.ant-select-arrow {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='130' height='130' fill='%23000000'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat !important;
  background-color: white !important;
  background-size: 15px !important;
  background-position: calc(100% - 5px) 14px !important;
  background-repeat: no-repeat !important;
}

.ant-select-item-option-grouped {
  display: none !important;
}

#DOBMonth,
#DOBDate,
#DOBYear {
  width: 100%;
  appearance: none;
  padding: 10px !important;
  margin: 0px 0;
  background-color: #ffffff !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 6px !important;
}

/* Small screens (mobile) */
@media (max-width: 575px) {
  .oneprofiles {
    border-left: 60px solid transparent;
    border-bottom: 60px solid #FDC300;
  }
}

/* Medium screens (tablet) */
@media (min-width: 576px) and (max-width: 767px) {
  .oneprofiles {
    border-left: 60px solid transparent;
    border-bottom: 80px solid #FDC300;
  }
}

/* Large screens (laptop) */
@media (min-width: 768px) and (max-width: 991px) {
  .oneprofiles {
    border-left: 60px solid transparent;
    border-bottom: 80px solid #FDC300;
  }
}

/* Extra-large screens (desktop) */
@media (min-width: 992px) {
  .oneprofiles {
    border-left: 60px solid transparent;
    border-bottom: 80px solid #FDC300;
  }
}

@media (min-width: 1200px) {
  .oneprofiles {
    border-left: 60px solid transparent;
    border-bottom: 60px solid #FDC300;
  }
}

/* div#profiles {
  border-left: 60px solid transparent;
  border-bottom: 80px solid #FDC300;
} */
div#profile {
  border-left: 60px solid transparent;
  border-bottom: 50px solid #FDC300;
}

.register-bottom {
  border-right: 60px solid transparent;
  border-bottom: 80px solid #FDC300;
}

ul li::marker {
  font-size: 20px;
  line-height: normal;
  color: #8F8F8F;

}

ol li::marker {
  font-size: 15px;
  line-height: normal;

}

p#underline::after {
  content: '';
  position: absolute;
  left: 2px;
  bottom: -2px;
  width: 110px;
  height: 2px;
  border: 2px solid #FDC300;
  border-radius: 100px;
  background: #FDC300;
}

.home-page-slider .slick-initialized {
  overflow-x: clip !important;
}

.home-page-slider .slick-arrow {
  z-index: 1;
  position: absolute;
}

.home-page-slider .slick-prev {
  left: 0px !important;
  height: 38rem !important;
  width: 3rem !important;
}

.home-page-slider .slick-prev:hover {
  background-color: #8F8F8F !important;
  /* opacity: 0.5 !important; */
    opacity: 0 !important;
}

.home-page-slider .slick-next:hover {
  background-color: #8F8F8F !important;
  /* opacity: 0.5 !important; */
    opacity: 0 !important;
}


.home-page-slider .slick-next {
  right: 0px !important;
  height: 42rem !important;
  width: 3rem !important;
}

.home-page-slider .slick-next:before,
.home-page-slider .slick-prev:before {
  opacity: 0 !important;
}

.popup-slider .slick-initialized {
  overflow-x: clip !important;
}

.popup-slider .slick-arrow {
  z-index: 1;
  position: absolute;
}

.popup-slider .slick-prev {
  left: -35px !important;
  height: 38rem !important;
  width: 3rem !important;
}

.popup-slider .slick-next:before,
.popup-slider .slick-prev:before {
  color: #161616 !important;
}

.matchespopup-slider .slick-initialized {
  overflow-x: clip !important;
}

.matchespopup-slider .slick-arrow {
  z-index: 1;
  position: absolute;
}

.matchespopup-slider .slick-prev {
  left: -35px !important;
  height: 17rem !important;
  width: 3rem !important;
}

.matchespopup-slider .slick-next:before,
.matchespopup-slider .slick-prev:before {
  color: #161616 !important;
}

.mob-top {
  position: absolute;
  left: 59%;
  top: 0;
}

div#top1 {
  min-width: 275px;
  position: relative;
  /* font-family: 'Josefin Sans', sans-serif; */
  margin-left: auto;
  margin-right: 181px;
  text-align: center;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  padding-bottom: 15px;
  padding-top: 2px;
  margin-top: -3px;
  z-index: 1;
}

#top1:before {
  content: "";
  position: absolute;
  background: #D10A10;
  top: 0px;
  bottom: 23%;
  left: -3%;
  right: -3%;
  z-index: -1;
  -webkit-transform: perspective(50em) rotateX(-30deg);
  transform: perspective(10em) rotateX(-30deg);
}

.mobview {
  display: none !important;
}

.webview {
  display: block !important;
}

.similarSlider {
  top: 1rem
}

.deserveBackground {
  background-image: url("../src/Images/Group-817.png");
  width: 100%;
  background-position: initial;
  background-repeat: round;
}

@media only screen and (max-width: 900px) {
  .deserveBackground {
    background-image: url("../src/Images/Group-817-res-1.webp");
    width: 100%;
    background-position: initial;
    background-repeat: round;
  }

  .similarSlider {
    top: 0rem
  }

  .rightSideSlider .slick-prev {
    left: 7rem !important;
    display: block;
    top: 24.5rem !important;
    z-index: 9;
  }

  .rightSideSlider .slick-next {
    right: 7rem !important;
    display: block;
    top: 24.5rem !important;
    z-index: 9;
  }

  .rightSideSimilarSlider .slick-prev {
    left: 7rem !important;
    display: block;
    top: 16.5rem !important;
    z-index: 9;
  }

  .rightSideSimilarSlider .slick-next {
    right: 7rem !important;
    display: block;
    top: 16.5rem !important;
    z-index: 9;
  }

  .mobview {
    display: block !important;
  }

  .webview {
    display: none !important;
  }

  .mob-top {
    position: absolute;
    left: 49%;
    top: 0;
  }

  div#top1 {
    min-width: 212px;
    position: relative;
    /* font-family: 'Josefin Sans', sans-serif; */
    margin-left: auto;
    margin-right: 181px;
    text-align: center;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    padding-bottom: 15px;
    padding-top: 2px;
    margin-top: -3px;
    z-index: 998;
  }

  #top1:before {
    content: "";
    position: absolute;
    background: #D10A10;
    top: 0px;
    bottom: 23%;
    left: -3%;
    right: -3%;
    z-index: -1;
    -webkit-transform: perspective(50em) rotateX(-30deg);
    transform: perspective(10em) rotateX(-30deg);
  }
}

@media only screen and (max-width: 600px) {

  .mob-top {
    position: absolute;
    right: 0%;
    top: 0;
  }

  div#top1 {
    min-width: 186px;
    position: relative;
    /* font-family: 'Josefin Sans', sans-serif; */
    margin-left: auto;
    margin-right: 181px;
    text-align: center;
    color: #fff;
    font-size: 8px;
    font-weight: bold;
    padding-bottom: 15px;
    padding-top: 2px;
    margin-top: -3px;
    z-index: 998;
  }

  #top1:before {
    content: "";
    position: absolute;
    background: #D10A10;
    top: 0px;
    bottom: 23%;
    left: -3%;
    right: -3%;
    z-index: -1;
    -webkit-transform: perspective(50em) rotateX(-30deg);
    transform: perspective(10em) rotateX(-30deg);
  }
}

.popup-slider .slick-next {
  right: -35px !important;
  height: 42rem !important;
  width: 3rem !important;
}

.popup-slider .slick-next:before,
.popup-slider .slick-prev:before {
  opacity: 1 !important;
}

/* ellipse */
.matchespopup-slider .slick-next:before,
.popup-slider .slick-prev:before {
  opacity: 1 !important;
}

.matchespopup-slider .slick-next {
  right: -35px !important;
  height: 42rem !important;
  width: 3rem !important;

}

.cut-text {
  width: 63px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* scroll bottom to top */

/* .sub {
  height: 100px;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
} */

.scrollBottom {

  overflow-y: scroll;

  height: 32rem;
  transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  /* Mozilla */
  -webkit-transform: rotateX(180deg);
  /* Safari and Chrome */
  -ms-transform: rotateX(180deg);
  /* IE 9+ */
  -o-transform: rotateX(180deg);
  /* Opera */
}

@media only screen and (max-width: 700px) {
  .scrollBottom {
    overflow-y: scroll;

    height: 25rem;
    transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
    /* Mozilla */
    -webkit-transform: rotateX(180deg);
    /* Safari and Chrome */
    -ms-transform: rotateX(180deg);
    /* IE 9+ */
    -o-transform: rotateX(180deg);
    /* Opera */
  }
}

@media (min-width: 600px) and (max-width: 851px) {
  .scrollBottom {
    overflow-y: scroll;
    height: 23.7rem;
    transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
    /* Mozilla */
    -webkit-transform: rotateX(180deg);
    /* Safari and Chrome */
    -ms-transform: rotateX(180deg);
    /* IE 9+ */
    -o-transform: rotateX(180deg);
    /* Opera */
  }
}

@media (min-width: 393px) and (max-width: 600px) {
  .scrollBottom {
    overflow-y: scroll;

    height: 26rem;
    transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
    /* Mozilla */
    -webkit-transform: rotateX(180deg);
    /* Safari and Chrome */
    -ms-transform: rotateX(180deg);
    /* IE 9+ */
    -o-transform: rotateX(180deg);
    /* Opera */
  }
}

.message-container {
  overflow-y: scroll;
  height: 32rem;
}

/* @media only screen and (max-width: 700px) {
  .message-container {
    overflow-y: scroll;

  height:38rem;
  }
} */

@media (min-width: 600px) and (max-width: 851px) {
  .message-container {
    overflow-y: scroll;
    height: 23.7rem;
  }
}

@media (min-width: 390px) and (max-width: 600px) {
  .message-container {
    overflow-y: scroll;
    height: 28rem;
  }
}

@media (min-width: 280px) and (max-width: 375px) {
  .message-container {
    overflow-y: scroll;
    height: 17rem;
  }
}


.sub {
  transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  /* Mozilla */
  -webkit-transform: rotateX(180deg);
  /* Safari and Chrome */
  -ms-transform: rotateX(180deg);
  /* IE 9+ */
  -o-transform: rotateX(180deg);
  /* Opera */
}

::placeholder {
  color: black !important;
}

/* Chrome, Safari, Edge, Opera */
.weight::-webkit-outer-spin-button,
.weight::-webkit-inner-spin-button {
  -webkit-appearance: auto;
  margin: 0;
}

/* Firefox */
input[className=weight] {
  -moz-appearance: auto;
}

/* .scrollBottom > div:last-child {
  scroll-snap-align: start;
} */

::placeholder {
  color: black !important;
}

.ant-select-selection-item-content {
  color: #8F8F8F;
  font-size: 14px;
  font-weight: bold;
}

.search::placeholder {
  color: #D10A11 !important;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
}

/* loader */

.loaderTransperancy {
  background: none repeat scroll 0 0 black;
  position: fixed;
  display: block;
  opacity: 0.5;
  z-index: 1000001;
  left: 0;
  top: 0;
  height: 100% !important;
  width: 100% !important;
}

.loaderTransperancySpin {
  width: 12rem !important;
  height: 12rem !important;
  position: relative !important;
  left: 48% !important;
  top: 25% !important;
}

@media only screen and (max-width: 768px) {
  .loaderTransperancySpin {
    width: 12rem !important;
    height: 12rem !important;
    position: relative !important;
    left: 25% !important;
    top: 25% !important;
  }
}

.loaderTransperancySpinAfter {
  width: 12rem !important;
  height: 12rem !important;
  position: relative !important;
  left: 1% !important;
  top: 25% !important;
}

@media only screen and (max-width: 768px) {
  .loaderTransperancySpinAfter {
    width: 12rem !important;
    height: 12rem !important;
    position: relative !important;
    left: 2% !important;
    top: 25% !important;
  }
}


.loaderTransperancyGoogle {
  background: none repeat scroll 0 0 black;
  position: fixed;
  display: block;
  opacity: 0.5;
  z-index: 1000001;
  left: 0;
  top: 0;
  height: 100% !important;
  width: 100% !important;
}

@media only screen and (max-width: 768px) {
  .loaderTransperancySpinGoogle {
    width: 12rem !important;
    height: 12rem !important;
    position: relative !important;
    left: 25% !important;
    top: 25% !important;
  }
}

.loaderTransperancySpinGoogle {
  width: 12rem !important;
  height: 12rem !important;
  position: relative !important;
  left: 0% !important;
  top: 25% !important;
}


#fixedFooter footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.popup-warning-msg {
  position: absolute;
  background-color: #E6E6E6;
  z-index: 2;
  /* width: 100%; */
  padding: 2.3rem 2rem;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup-warning-msg-photo {
  position: absolute;
  background-color: #E6E6E6;
  z-index: 2;
  /* width: 100%; */
  padding: 1.3rem 2rem;
  margin: 0;
  top: 50%;
  /* left: 12.5%; */
  transform: translate(0%, -50%);
}

.popup-warning-msg-photo-page {
  position: absolute;
  background-color: #E6E6E6;
  z-index: 2;
  width: 12.1%;
  top: 43%;
  padding: 0 1rem 0 2rem;
}

.popup-warning-msg-paid {
  position: absolute;
  background-color: #E6E6E6;
  z-index: 51;
  width: 100%;
  padding: 2.3rem 2rem;
  margin: 0;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* ================ */
#DOBMonth>option:nth-child(1),
#DOBDate>option:nth-child(1),
#DOBYear>option:nth-child(1) {
  display: none !important;
}


.searchbox {
  color: #D10A11 !important;



}

.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.no-spinner[type="number"] {
  -moz-appearance: textfield;
}



.campaignfirst-card {


  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  box-shadow: -2px 1px 11px -3px;
}

.campaignsec-card {


  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 2px 1px 11px -3px;
}

.campaignList li::marker {
  font-size: 20px;
  line-height: normal;
  color: #ffffff;
}

.holds-the-iframe {
  background: url(../public/Assets/Gif/loader.gif) center center no-repeat;
}

.container {
  /* To position the loading */
  position: relative;
}

.loading {
  /* Absolute position */
  left: 0;
  position: absolute;
  top: 0;

  /* Take full size */
  height: 100%;
  width: 100%;

  /* Center */
  align-items: center;
  display: flex;
  justify-content: center;
}

.custom-next-arrow {
  position: absolute;
  top: 50%;
  /* right: 10px;  */
  transform: translateY(-50%);
}

.down-arrow .ant-select-selector {
  width: 100%;
  height: 40px !important;
  padding: 5px 11px !important;
  overflow: hidden;
  font-size: 12px;
}

.down-arrow .ant-select-selection-item-content {
  color: #495057;
  font-size: 14px;
}

.down-arrow .ant-select-selection-placeholder {
  color: #495057;
}

.down-arrow .ant-select-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../src/Images/Polygon 22.svg") no-repeat;
  background-color: white;
  background-size: 15px;
  background-position: calc(100% - 5px) 14px;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 767px) {

  .down-arrow.ant-select-selector {
    width: 98%;
  }

  .down-arrow.ant-select {
    height: 2rem;
  }

  .down-arrow .ant-select-selection-placeholder {
    color: #495057;
    font-size: 10px !important;
    line-height: 21px !important;
  }
}




.new .ant-select-selector {
  background-color: #E6E6E6 !important;
  border: none !important;
  height: 46px !important;
  padding: 10px 15px !important;
  /* Add padding to position the text */
  display: flex !important;
  /* Ensure flexbox behavior */
  align-items: center !important;
  /* Vertically center the content */
  line-height: 1.2 !important;
  /* Adjust line height for centering text */
  overflow: hidden !important;
  /* Prevent text overflow */
  text-overflow: ellipsis !important;
  /* Ensure text doesn't overflow */
  white-space: nowrap !important;
  /* Prevent text from wrapping */

}

.new .ant-select-selection-item-content {
  color: #000000 !important;
  font-weight: 500 !important;
}

.chartstyle {
  background-color: #E6E6E6 !important;
  border: none !important;
  color: #000000 !important;
  /* font-weight: 500 !important; */

}

.new .ant-select-arrow {
  background-color: #e5e7eb !important;
}

.after-reg-isd .ant-select-arrow {
  background-color: #e5e7eb !important;
}

/* .after-reg-isd .ant-select-dropdown{
  width: 200px !important;
} */

.arrow-clickabale {
  pointer-events: none;
}

.newone {
  /* background-color: #E6E6E6 !important; */
  border: none !important;
  height: 50px !important;
}

.newone {
  color: #000000 !important;
  font-weight: 500 !important;
}


.RegPhoneNo::-webkit-inner-spin-button,
.RegPhoneNo::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#isdCode {
  background: #ffffff;
}

.premier-slide .slick-slide {
  margin: 0 20px;
}

/* Firefox-specific styles */
@-moz-document url-prefix() {

  @media (min-width: 768px) {
    .serviceclass {
      font-size: 9px !important;
      /* Firefox-specific font size */
      line-height: 1.3;
    }

    .capsule {
      width: 9rem !important;
    }

    .switch-to-new {
      font-size: 14px;
    }

    .photo-popup {
      font-size: 12px !important;
    }

  }

  @media (min-width: 1024px) {

    .serviceclass {
      font-size: 10px !important;
      /* Firefox-specific font size */
      line-height: 1.6;
    }

    .capsule {
      width: 10.5rem !important;
    }

    .switch-to-new {
      font-size: 14px;
    }

    .photo-popup {
      font-size: 12px !important;
    }

    input[type="checkbox"]#isWhatsappNumber::before {
      width: 1.1em;
    }
  }
}

.slick-current .custom-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid green;
  /* Border color for the current slide */
  transform: scale(1);
  /* Increase size for the current slide */
  z-index: 10;
  /* Increase z-index for the current slide */
}

.custom-right-arrow {
  position: absolute;
  height: 2.5rem;
  width: 2.5rem;
  top: 40%;
  right: -12%;
}

.custom-left-arrow {
  position: absolute;
  height: 2.5rem;
  width: 2.5rem;
  top: 40%;
  left: -12%;
}

@media (max-width: 767px) {
  .custom-left-arrow {
    position: absolute;
    height: 2.5rem;
    width: 2.5rem;
    top: 43%;
    left: -28%;
  }

  .custom-right-arrow {
    position: absolute;
    height: 2.5rem;
    width: 2.5rem;
    top: 43%;
    right: -28%;
  }
}

@media (max-width: 375px) {
  .custom-left-arrow {
    position: absolute;
    height: 2.5rem;
    width: 2.5rem;
    top: 43%;
    left: -22%;
  }

  .custom-right-arrow {
    position: absolute;
    height: 2.5rem;
    width: 2.5rem;
    top: 43%;
    right: -22%;
  }

}

@media (max-width: 369px) {
  .custom-left-arrow {
    position: absolute;
    height: 2.5rem;
    width: 2.5rem;
    top: 43%;
    left: -22%;
  }

  .custom-right-arrow {
    position: absolute;
    height: 2.5rem;
    width: 2.5rem;
    top: 43%;
    right: -22%;
  }

}

@media (max-width: 767px) {
  .arrowleft {
    position: absolute;
    height: 2.5rem;
    width: 2.5rem;
    top: 45%;
    left: -22%;
  }

  .arrowright {
    position: absolute;
    height: 2.5rem;
    width: 2.5rem;
    top: 45%;
    right: -22%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .arrowleft {
    position: absolute;
    height: 2.5rem;
    width: 2.5rem;
    top: 40%;
    left: -22% !important;
  }

  .arrowright {
    position: absolute;
    height: 2.5rem;
    width: 2.5rem;
    top: 40%;
    right: -22% !important;
  }

  input[type="checkbox"]#isWhatsappNumber::before {
    width: 1.2em;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .custom-left-arrow {
    position: absolute;
    height: 2.5rem;
    width: 2.5rem;
    top: 46%;
    left: -12%;
  }

  .custom-right-arrow {
    position: absolute;
    height: 2.5rem;
    width: 2.5rem;
    top: 46%;
    right: -12%;
  }
}

.custom-right-arrows {
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  top: 40%;
  right: -9%;
}

.custom-left-arrows {
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  top: 40%;
  left: -9%;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .custom-left-arrows {
    position: absolute;
    height: 2rem;
    width: 2rem;
    top: 40%;
    left: -15%;
  }

  .custom-right-arrows {
    position: absolute;
    height: 2rem;
    width: 2rem;
    top: 40%;
    right: -15%;
  }
}

@media (max-width: 369px) {
  .custom-left-arrows {
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    top: 40%;
    left: -10%;
  }

  .custom-right-arrows {
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    top: 40%;
    right: -10%;
  }
}

@media (max-width: 375px) {
  .custom-left-arrows {
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    top: 40%;
    left: -11%;
  }

  .custom-right-arrows {
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    top: 40%;
    right: -11%;
  }
}

@media (max-width: 767px) {
  .custom-left-arrows {
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    top: 40%;
    left: -12%;
  }

  .custom-right-arrows {
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    top: 40%;
    right: -12%;
  }
}

/* ========================== */
.audio-recorder-options~.audio-recorder-options {
  display: none !important;
}

.audio-recorder.recording .audio-recorder-mic {
  display: none !important;
}

.slick-list {
  padding: 0 !important;
  /* Adjust the margin value as needed */
}

.plyr--audio {
  border-radius: 10px !important;
  width: 13rem !important;
}

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px;
  position: absolute;
  margin-top: 22%;
  left: 17%;
  font-size: 10px !important;
}

/* Adjust spacing between controls and current time */
.plyr__controls {
  margin-bottom: 0.8rem !important;
  width: 11rem !important;
  padding: 10px 15px 10px 5px !important;
}

.plyr--audio .plyr__control:focus-visible,
.plyr--audio .plyr__control:hover,
.plyr--audio .plyr__control[aria-expanded=true] {
  background: white !important;
  background: var(--plyr-audio-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, black)));
  color: #4a5464 !important;
  color: var(--plyr-audio-control-color-hover, #fff);
}

.plyr .plyr--full-ui .plyr--audio .plyr--html5 .plyr--paused .plyr--stopped {
  width: 11rem !important;
}

.plyr__progress__container {
  width: 8rem !important;
  /* Adjust the width as needed */
}

.box {
  height: auto;
  position: relative;
  float: left;
}

.box.arrow-left:after {
  content: " ";
  position: absolute;
  left: -15px;
  top: -18px;
  border-top: 11px solid transparent;
  border-right: 11px solid #F9DADB;
  border-left: none;
  rotate: -226deg;
  border-bottom: 15px solid transparent;
}

.box.arrow-right:after {
  content: " ";
  position: absolute;
  right: -5px;
  top: -9px;
  border-top: 7px solid transparent;
  border-right: 9px solid white;
  border-left: none;
  rotate: -106deg;
  border-bottom: 15px solid transparent;
}

.lite-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.lite-scrollbar::-webkit-scrollbar-thumb {
  background: #D8D8D8;
  border-radius: 10px;
}

.lite-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.custom-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  /* Adjust the padding as needed */
  line-height: 20px;
  /* Adjust the line height as needed */
  cursor: pointer;
}

/* Style for the checkbox input */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Style for the checkbox border */
.checkmark {
  position: absolute;
  top: -17px;
  left: 0;
  border-radius: 5px;
  height: 21px;
  width: 21px;
  border: 1px solid #707070;
  background-color: white;
}

.list-filter {
  top: -12px !important;
}

/* Style for the tick mark inside the checkbox */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 2px;
  width: 5px;
  height: 12px;
  border: solid #D10A11;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  /* background-image: url('./Images/singleredtick.svg'); */
}

/* Show the tick mark when the checkbox is checked */
.custom-checkbox input:checked+.checkmark:after {
  display: block;
}

input[type="checkbox"].lite {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  width: 1rem !important;
  height: 1rem !important;
  margin: 0 !important;
  font: inherit !important;
  color: currentColor !important;
  cursor: pointer;
  display: grid !important;
  place-content: center !important;
  transform: translateY(-0.075em) !important;
  background-image: url(./Images/singlegreytick.svg) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  border: none !important;
  background-color: transparent;
  background-size: 100%;
}

input[type="checkbox"].lite::before {
  content: "" !important;
  width: 1rem !important;
  height: 1rem !important;
  border-radius: 0.15em !important;
  transition: 120ms transform ease-in-out !important;
  background-image: url(./Images/singleredtick.svg) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-color: transparent !important;
  background-size: 100%;
  cursor: pointer;

}

input[type="checkbox"]:checked.lite {
  background: none !important;
}

.shadow-right-bottom {
  box-shadow: 3px 3px 5px rgba(216, 216, 216, 1);
  /* You can adjust the values as needed */
}

.chat-background {
  background-image: url("Components/Kalyanlite/assets/Images/ChatBackground.jpg");
  background-repeat: repeat;
}



/* .accordion-button.collapsed {
  border-bottom: #ccc 1px solid
}

.accordion-body {
  border-left: #673ab744 1px solid;
  border-bottom: #673ab744 1px solid;
  border-right: #673ab744 1px solid
} */
/* .accordions div:has(~ .hidden){ border:1px solid red !important; } */

.accordions div:nth-child(1):has(~ .hidden) {
  border: none !important;
}

.ant-popover-arrow {
  display: none !important;
}

.ant-popover-content {
  position: relative !important;
  right: 7rem !important;

  top: -2.5rem !important;
}

.bgColor div:nth-child(odd) p {
  background-color: #E9E9E9 !important;
}

.bgColor div:nth-child(even) p {
  background-color: #DCDCDD !important;
}

.search-find {
  border-radius: 3px;
  animation: highlight 2000ms ease-out;
}

@keyframes highlight {
  from {
    background-color: #cbd5e1;
  }
}


/* 
.ant-popover.css-dev-only-do-not-override-5x9nmt.logoutpopup {
  top: 87px !important;
  left: 978px !important;
}

@media (min-width: 992px) {
  .ant-popover.css-dev-only-do-not-override-5x9nmt.logoutpopup {
    top: 60px !important;
    left: 873px !important;
  }
}

@media (min-width: 1200px) {
  .ant-popover.css-dev-only-do-not-override-5x9nmt.logoutpopup {
    top: 60px !important;
    left: 978px !important;
  }
}

@media (min-width: 1400px) {
  .ant-popover.css-dev-only-do-not-override-5x9nmt.logoutpopup {
    top: 50px !important;
    left: 1387px !important;
  }
} */

/* Chat page three dot popup mobile view */
@media only screen and (max-width: 767px) {
  .ant-popover-content {
    right: 3rem !important;
    position: relative;
    top: -0.3rem !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .ant-popover-content {
    right: 0 !important;
    position: relative;
    top: -2.5rem;
  }
}

.custom-font-family {
  font-family: 'Segoe-semiBold' !important;
}

.preview-slider-center .slick-list {
  display: flex;
  justify-content: center;
}

.preview-slider .custom-left-arrow,
.preview-slider .custom-right-arrow {
  height: 20px;
  width: 20px;

}

.preview-slider .preview-slider-full-item {
  display: flex !important;
}

.match-popup .ant-modal-content {
  padding: 10px;
}

.match-popup {
  width: auto !important;
}

@media only screen and (max-width: 375px) {
  .profile-avatar-group {
    padding-right: 32px;
  }
}

@media only screen and (max-width: 375px) {
  .mobilesearch {
    height: 11% !important;
  }
}

@media only screen and (max-width: 375px) {

  .paynowmobile {
    height: 60dvh;
    overflow-y: auto !important;
  }

  .paynowpage {
    height: 64%;
  }
}

@media only screen and (min-width: 384px) {

  .paynowmobile {
    height: 61dvh;
    overflow-y: auto !important;
  }

}

@media only screen and (min-width: 390px) {

  .paynowmobile {
    height: 77%;
    overflow-y: auto !important;
  }

}

.page-hight-reduce {
  /* height: calc(100% - 10rem) !important; */
}

@media only screen and (max-width: 767px) {
  .mblpagepaynow-hight-reduce {
    height: calc(100% - 12rem) !important;
  }
  .mblpagepaynow-hight-reduce-addon {
    height: calc(100% - 8.5rem) !important;
  }
}

.familyselect select {
  display: block;
  min-height: 40px;
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid #C4C4C4;
  outline: none;
  padding: 0 20px 0 10px;
  margin-top: 5px;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}

.family select {
  display: block;
  min-height: 2.3rem !important;
  /* background: #F0F2F5; */
  border-radius: 3px;
  border: 1px solid #C4C4C4;
  outline: none;
  padding: 0 20px 0 10px;
  margin-top: 5px;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}

.parentpopup select {
  display: block;
  min-height: 40px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #C4C4C4;
  outline: none;
  padding: 0 20px 0 10px;
  margin-top: 5px;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}

@media (min-width: 340px) and (max-width: 374px) {
  .filter-menu-height {
    height: 64vh;
  }

  .filter-footer-button {
    width: 85px;
  }

  .filterDifferent_profile_button {
    width: 260px;
  }

  .filter-left-menu {
    height: 59vh;
  }

  .filter-dropdown {
    width: 170px;
  }

  .filter-dropdown-content {
    width: 90px;
  }

  .filter-dropdown-values {
    width: 180px;
  }

  .filter-education-height {
    height: 41vh;
  }
}

@media (min-width: 375px) and (max-width: 389px) {
  .filter-menu-height {
    height: 69vh;
  }

  .filter-left-menu {
    height: 62vh;
  }

  .filter-education-height {
    height: 46vh;
  }
}

@media (min-width: 340px) and (max-width: 370px) {

  .select-section {
    padding-left: 10px;
    gap: 0;
  }

  .select-gap {
    margin-left: 0 !important;
  }

  .select-horo {
    padding-left: 10px;
  }

}

@media (min-width: 340px) and (max-width: 380px) {
  .select-name {
    width: 8.5rem;
    height: 3rem;
  }

  .hob-detail {
    margin-left: 1rem;
  }

  .hob-item {
    padding-left: 1.2rem;
  }

  .hob-width {
    width: 15rem;
  }

  .mp-hob-detail {
    padding-left: .7rem;
  }


}

@media (min-width: 340px) and (max-width: 374px) {
  .three-dot {
    right: 4.3rem !important;
  }
}

.horoscope select {
  display: block;
  min-height: 35px;
  min-width: 60px;
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid #8f8f8f;
  outline: none;
  padding: 0 20px 0 10px;
  margin-top: 5px;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}

@media (width: 820px) {
  .forget-password {
    padding-left: 0.7rem !important;
  }
}

@media (width: 340px) {
  .mbl-forget-password {
    left: 10rem !important;

  }

  .mbl-forget_passwort {
    margin-left: 0.5rem !important;
  }
}

@media (width: 412px) {
  .mbl-forget-password {
    left: 13rem !important;

  }

  .mbl-forget_passwort {
    margin-left: 0.5rem !important;
  }
}

.box {
  width: 250px;
  margin: 11px auto;
  background: "bg-gray-300 ";
  text-align: center;
  font-weight: 900;
  color: #fff;
  font-family: arial;
  position: relative;
}

.sb1:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid #D2D6DB;
  border-right: 10px solid transparent;
  border-top: 10px solid #D2D6DB;
  border-bottom: 10px solid transparent;
  right: -19px;
  top: 7px;
}

.sb2:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid #D2D6DB;
  border-top: 10px solid #D2D6DB;
  border-bottom: 10px solid transparent;
  left: -19px;
  top: 7px;
}

.backgroundimage {
  /* background-image: url("../src/Images/marriage-pic.webp"); */
  background-image: url("../src/Images/mobileSlideImg.png");
  width: 100%;
  background-position: initial;
  background-repeat: round;
}

.match-list-count-badge .ant-badge-count {
  font-weight: 900;
  color: black;
  box-shadow: none !important;
  font-family: 'Poppins-SemiBold';
  font-size: 12px;
}

@media (min-width: 393px) {
  #switchlanguage {

    right: 10px;
    top: auto;
    transform: translateY(-100%);

  }
}

@media (max-width: 340px) {
  #switchlanguage {

    right: 10px;
    top: auto;
    transform: translateY(-100%);

  }
}

@media (min-width: 1024px) {
  #switchlanguage {

    right: -70px;
    top: auto;
    transform: translateY(-100%);

  }
}

#switchtop {
  top: calc(100% - 5px);
}

.ant-modal-title {
  color: #D10A11 !important;
  /* border-bottom: 1px dashed #D10A11; */
  font-size: 18px !important;
  font-weight: bold !important;
}

@media (min-width: 1025px) and (max-width: 1279px) {
  .habits-image {
    width: 2.2rem !important;
    height: 2.2rem !important;
  }

  /* .habits-details {
    margin-top: 0.4rem !important;
  } */

  .mp-habits-details {
    padding-left: 1.2rem !important;
    padding-right: 1.2rem !important;
  }
}

.custom-tooltip .ant-tooltip-inner {
  background-color: white;
  border: none;
  color: #000000;
}

.fontRubik {
  font-family: normal normal medium 100px/116px Rubik
}

.leftdesign {
  height: -webkit-fill-available;
  max-width: -webkit-fill-available;
}

.custom-tooltip .ant-tooltip-arrow::after {
  background-color: white;
}

#whatsappnumber::placeholder {
  color: #575556 !important;
}

#moreInfo::placeholder{
  color:#575556 !important;
}

.number::placeholder{
  color:#575556 !important;
}
@media (min-width: 1536px) {
  .profilepic {
    width: 100%;
    height: 28rem;

  }
}

@media (min-width: 1809px) and (max-width:1869) {
  .profilepic {
    width: 100%;
    height: 28rem;

  }
}

.profilepic {
  width: 100%;
  height: 25rem;

}

@media (min-width: 914px) and (max-width:1024px) {
  .line {
    width: 3rem !important;
    margin-right: .8rem;

  }
}

.filtercount .ant-badge-multiple-words {
  font-weight: bolder !important;
}

input[type="checkbox"]#isWhatsappNumber::before {
  width: 1.2em;
  height: 1.2em;
}

@keyframes placeholderFade {
  0% {
    opacity: 0;
    transform: translateY(60%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@media only screen and (max-width: 768px) {
  @keyframes placeholderFade {
    0% {
      opacity: 0;
      transform: translateY(150%);
    }

    100% {
      opacity: 1;
      transform: translateY(50%);
    }
  }
}

.animated-input {
  animation: placeholderFade 4s infinite !important;
}

.selectheight select {
  display: block;
  min-height: 35px;
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid #C4C4C4;
  outline: none;
  padding: 0 20px 0 10px;
  margin-top: 5px;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}

.selectheight select::after {
  content: '';
  border-width: 5px;
  border-style: solid;
  border-color: transparent;
  border-top-color: #C4C4C4;
  display: inline-block;
  border-radius: 2px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}


/* Ensure the "Others" option stays at the bottom and does not scroll */
.dropdown-option-others .rc-virtual-list .rc-virtual-list-holder .rc-virtual-list-holder-inner {
  position: sticky !important;
  bottom: 0 !important;
  background-color: rgb(206, 22, 22) !important;
}

.dropdown-option-others {
  position: sticky !important;
  bottom: 0 !important;
  background-color: white !important;
  z-index: 1 !important;

}


.dropdown-options {
  max-height: 150px;
  overflow-y: auto;
}


.bannerCarousel .slick-prev {
  width: 16px;
  position: absolute;
  left: 6px;
  z-index: 1;
}

.bannerCarousel .slick-next {
  width: 16px;
  position: absolute;
  right: 6px;
}

.arrow .ant-popover-arrow {
  display: block !important;
  left: 47px !important;
  top: 23px;
  background: url("../src/Images/Downarrow.svg") no-repeat;
  transform: translateX(50%) translateY(100%) rotate(0deg);
}

.arrow .ant-popover-arrow::before {
  display: none !important
}

@media (max-width: 768px) {
  .arrow .ant-popover-arrow {
    left: 61px !important;
    top: 26px;
  }
}

.highlight .ant-select-selector {
  border: none !important;
}

/*  */
.arrow-box::before {
  content: '';
  position: absolute;
  top: 0%;
  left: 0px;
  /* Adjust this value to move the arrow horizontally */
  border: solid transparent;
  border-width: 13px;
  border-left-color: #F9DADB;
  /* Same as background color */
}

.share-profilebox::after {
  content: '';
  position: absolute;
  top: 0%;
  right: 0px;
  /* Adjust this value to move the arrow horizontally */
  border: solid transparent;
  border-width: 13px;
  border-right-color: #CCD6E3;
  /* Same as background color */
}

.view-profilebox:after {
  content: '';
  position: absolute;
  top: 0%;
  right: 0px;
  /* Adjust this value to move the arrow horizontally */
  border: solid transparent;
  border-width: 14px;
  border-right-color: #ffffff;
  /* Same as background color */
}

.absolute-others {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: white;
  /* Match the background color of the dropdown */
  z-index: 1;
  border-top: 1px solid #f0f0f0;
  /* Optional: add a border to separate it visually */
  padding: 8px;
  /* Ensure padding matches dropdown item padding */
}

.regular-options {
  position: relative !important;
}

.absolute-others .ant-select-item .ant-select-item-option {

  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
}

.absolute-others .ant-select-item .ant-select-item-option[title="Others"] {
  position: fixed !important;
  bottom: 0 !important;
}

.css-1n6sfyn-MenuList {
  padding-bottom: 0px !important;
}

/* Global or scoped CSS */

.sticky-bottom {
  position: sticky !important;
  bottom: 0 !important;
  background-color: #ffffff;
  /* Adjust background color as needed */
  z-index: 1;
  /* Adjust z-index as needed */
  padding: 8px 16px;
  /* Adjust padding as needed */
  border-top: 1px solid #e8e8e8;
  /* Optional: Add border-top for separation */
}

.css-jzkmfj-MenuList .css-kgl5bc-MenuList {
  padding-bottom: 0px !important;
}

.dropdown-option-others {
  position: sticky !important;
  bottom: 0;
  background-color: white !important;
  /* Adjust the background color as needed */
  z-index: 1;
}

/* .sticky-option {
  position: fixed !important;
  bottom: 0 !important;
  background-color: white !important;
  padding: 8px !important; 
  border-top: 1px solid #e8e8e8 !important; 
  cursor: pointer !important;
} */

.ant-select-dropdown {
  position: absolute;
  /* Ensure the dropdown is positioned absolutely */
  z-index: 1000;
  /* Adjust z-index as needed */
  background-color: #fff;
  /* Background color of the dropdown */
  border: 1px solid #d9d9d9;
  /* Border color */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  /* Box shadow */
  max-height: 304px;
  /* Max height of the dropdown to enable scrolling */
  overflow-y: auto;
  /* Enable vertical scrolling */
  color: #000000;
}

.othersposition {
  position: absolute !important;
  /* Ensure the dropdown is positioned absolutely */
  z-index: 1000;
  /* Adjust z-index as needed */
  background-color: #a72222;
  /* Background color of the dropdown */
  border: 1px solid #d9d9d9;
  /* Border color */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  /* Box shadow */
  max-height: 304px;
  /* Max height of the dropdown to enable scrolling */
  overflow-y: auto;
  /* Enable vertical scrolling */
  color: #000000;
}

.sticky-option {
  padding: 8px 12px;
  /* Adjust padding as needed */
  cursor: pointer;
  /* Pointer cursor */
  background-color: #F7F8FA;
}

.sticky-option:hover {
  background-color: #E9E9E9;
  /* Hover background color */
  color: #000000;
  /*  */
}

.filter-white {
  filter: grayscale(100%) sepia(100%) hue-rotate(0deg) saturate(1000%) brightness(50%);
}

.kalyan-coin {
  animation: flip 2s infinite;
}

@keyframes flip {
  0% {
    transform: perspective(600px) rotateY(0deg);
  }

  100% {
    transform: perspective(600px) rotateY(360deg);
  }
}

.reward-badge .ant-badge-count {
  scale: 0.6;
  color: black;
  font-weight: 600;
}

.Additional::placeholder {
  color: #575556 !important;
}

/* Container for the select element */
.select-container {
  position: relative;
  width: 100%;
}

/* Style for the select element */
.custom-select {
  width: 100%;
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  color: #000000;
  padding: 10px;
  font-size: 13px;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  appearance: none;
  /* Remove default select styling */
}

/* Style for the dropdown */
.custom-select-wrapper {
  position: relative;
}

/* Option styles */
.custom-select option {
  padding: 10px;
}

/* Ensure "Others" option is sticky */
.custom-select option.others-option {
  background-color: #a72222;
  /* Customize as needed */
  color: #ffffff;
  position: sticky;
  bottom: 0;
  z-index: 1;
}

/* Add padding to ensure dropdown shows correctly */
.custom-select::-ms-expand {
  display: none;
  /* Remove default dropdown arrow in IE */
}

.custom-options {
  position: absolute;
  top: 100%;
  /* Place it directly below the select */
  left: 0;
  width: 100%;
  max-height: 200px;
  /* Adjust height as needed */
  overflow-y: auto;
  /* Scroll if content overflows */
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  /* Ensure it fits within the container */
  display: flex;
  flex-direction: column;
}

/* Style for individual options */
.custom-option {
  padding: 10px;
  cursor: pointer;
  /* Change cursor to pointer */
}

.familynew {
  /* background-color: #E6E6E6 !important; */
  border: none !important;
  height: 46px !important;
}

.familynew {
  color: #000000 !important;
  font-weight: 500 !important;
}

.familynew .ant-select-arrow {
  background-color: #e5e7eb !important;
}

.familynew .ant-select-selector {
  background-color: #E6E6E6 !important;
  border: none !important;
  height: 46px !important;
}

.familynew .ant-select-selection-item-content {
  color: #000000 !important;
  font-weight: 500 !important;
}

.add .ant-select-selector {
  background-color: #E6E6E6 !important;
  border: none !important;
  height: 46px !important;
  padding: 10px 15px !important;
  /* Add padding to position the text */
  display: flex !important;
  /* Ensure flexbox behavior */
  align-items: center !important;
  /* Vertically center the content */
  line-height: 1.2 !important;
  /* Adjust line height for centering text */
  overflow: hidden !important;
  /* Prevent text overflow */
  text-overflow: ellipsis !important;
  /* Ensure text doesn't overflow */
  white-space: nowrap !important;
  /* Prevent text from wrapping */

}


.add .ant-select-selection-item-content {
  color: #000000 !important;
  font-weight: 500 !important;
}

.chartstyle {
  background-color: #E6E6E6 !important;
  border: none !important;
  color: #000000 !important;
  /* font-weight: 500 !important; */

}

.add .ant-select-arrow {
  background-color: #e5e7eb !important;
}

.newones .ant-select-selector {
  background-color: #E6E6E6 !important;
  border: none !important;
  height: 46px !important;
}

.newones .ant-select-arrow {
  background-color: #E6E6E6 !important;
}

.newones .ant-select-selection-item-content {
  color: #000000 !important;
  font-weight: 500 !important;
}

#feedback::placeholder {
  color: #C4C4C4 !important;
  font-size: 9px;
}

select {
  position: relative;
  max-height: 150px;
  /* Adjust height as needed */
  overflow-y: auto;
}

/* Ensure the last option is always visible at the bottom of the dropdown */
.fixed-bottom-option {
  position: absolute;
  bottom: 0;
  width: 100%;
}

/* Ensure other options scroll within the container */
.scrollable-options {
  max-height: 250px;
  /* Adjust height as needed */
  overflow-y: auto;
  position: relative;
}



.selectone {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='130' height='130' fill='%23F18181'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
  background-color: white;
}

.selectone {
  background-size: 15px;
  background-position: calc(100% - 5px) 14px;
  background-repeat: no-repeat;
}

#feedback::placeholder {
  color: #717171 !important;
  font-size: 9px;
}

.text-small {
  font-size: 13px;
  /* Size for multi-line text */
}

.text-large {
  font-size: 18px;
  /* Size for single-line text */
}

#Family::placeholder {
  color: #717171 !important;
  font-size: 12px;
}

.placeholder-color::placeholder {
  color: #575556 !important;
}

.additional .ant-select-selector {
  background-color: #ffffff !important;
  border: 1px solid #7A7A7A !important;
}

.AdditionalFamily::placeholder {
  color: #575556 !important;
  font-size: 12px;
  font-weight: 400;
}

.assets .ant-select-selection-placeholder {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  color: #575556 !important;
  pointer-events: none;
  font-weight: 400;
}

.familyone .ant-select-selection-search {
  padding-top: .4rem;
}

.new .ant-select-selector .ant-select-selection-placeholder {

  line-height: 34px !important;
}

.familynew .ant-select-selector .ant-select-selection-item {
  line-height: 36px !important;
}

.newones .ant-select-selector .ant-select-selection-item {
  line-height: 34px !important;
}

.Toastify__toast Toastify__toast-theme--light Toastify__toast--default {
  background-color: red;
}

@media (max-width: 1024px) {
  .center-align {
    align-items: center;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .horoscope select {
    display: block;
    min-height: 35px;
    min-width: 50px;
    background: #ffffff;
    border-radius: 3px;
    border: 1px solid #8f8f8f;
    outline: none;
    padding: 0 20px 0 10px;
    margin-top: 5px;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
  }
}

.nriBackgroundImage {
  background-image: url("https://km-horoscope-response.s3.us-west-2.amazonaws.com/resources/dist1/images/slider_4.jpg");
  width: 100%;
  background-position: initial;
  background-repeat: round;
}

.quotedText::before {
  background-image: url('./Components/Kalyanlite/assets/Images/quote.svg');
  transform: rotate(120deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  margin-right: .5rem;
}

.quotedText::after {
  background-image: url('./Components/Kalyanlite/assets/Images/quote.svg');
  vertical-align: -webkit-baseline-middle;
  margin-left: .5rem;
}

.quotedText::after,
.quotedText::before {
  content: "";
  width: 18px;
  height: 18px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width: 768px) {

  .quotedText::after,
  .quotedText::before {
    width: 15px;
    height: 15px;
  }
}

@media (min-width:900px) and (max-width: 1060px) {
  .template-width {
    width: 20rem !important;
  }
}

/* .scroll-Container{
  
} */
.scroll-Container {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  max-height: calc(100vh - 24rem);
}

.scroll-Container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

@layer components {
  .popover-arrow .ant-popover-arrow {
    @apply !block lg:!top-[-2.5rem] xl:!top-[-2.5rem] 2xl:!top-[-2.5rem] lg:!left-[-5.5rem] xl:!left-[-5.5rem] 2xl:!left-[-5.5rem];
  }
}
.popover-arrow .ant-popover{
 top: 276.054px !important;
}

/* Hide the check icon inside the selected item when parent has the 'monthly' class */
.monthly .ant-select-selection-item .anticon-check {
  display: none !important;
}

/* Apply padding to selected item when .monthlyValue class is present on the select box */
.monthlyValue .ant-select-selector {
  padding-top: 2.5rem !important; /* Equivalent to pt-8 */
}

@media (min-width: 500px) and (max-width: 900px) {
  .monthlyValue .ant-select-selector {
    width: 80px !important; /* Adjust width for smaller screens */
  }
}

.scroll-Request {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  max-width: calc(100% - 4rem);
}

.scroll-Request::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}