.card-skeleton{
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0.5rem;
    border: 1px solid grey;
    border-radius: 5px;
}

.card-skeleton-id{
    height: 1.2rem;
}

.card-skeleton-name{
    height: 1rem;
}

.card-skeleton-age{
    height: 0.8rem;
}

.card-skeleton-description{
    height: 0.6rem;
}

.card-skeleton-action{
    height: 3rem;
    border-radius: 0%;
}
.card-skeleton-img{
    width: 10rem !important;
}

.card-skeleton-button{
    width: 6em !important;
}
.card-skeleton-profile-img{
    width: 7em !important;
    height: 6em !important;

}
.card-skeleton-icons{
    width: 1.5rem !important;
    height: 1.5rem !important;
    border-radius: 50% !important;
}
.card-skeleton-text{
    height: 0.5rem !important;
}
.card-skeleton-title{
    height: 1rem !important;
    width: 12rem !important;
    border-radius: 10px !important;
}