.react-datepicker-wrapper{
 font-weight: bold !important;
 color: #575556;
 border: 1px solid #8F8F8F !important;
 font-size: 14px !important;
 border-radius: 3px !important;
 height: 35px !important;
 width: 100% !important;
 padding: 0.65rem !important;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    color: #575556 !important;
}
.react-datepicker__current-month react-datepicker__current-month--hasYearDropdown react-datepicker__current-month--hasMonthDropdown{
    color: #575556 !important;
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before{
    border-color: #575556 !important;
}
.react-datepicker__header{
    background: #f3f3f3 !important;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
    left: -119px !important;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: #d10a11 !important;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
    background-color: #d10a11 !important;
    color: white !important;
    font-weight: bold ;
}

.react-datepicker-ignore-onclickoutside{
    outline: none !important;
}


.react-datepicker__month-select{
    appearance: auto !important;
    border: 1px solid #8F8F8F !important;
    background: white !important;
    outline: none !important;
    color: #575556 !important;
}

.react-datepicker__year-select{
    appearance: auto !important;
    border: 1px solid #8F8F8F !important;
    background: white !important;
    outline: none !important;
    color: #575556 !important;

}

.basic-date-picker{
    font-weight: bold !important;
    color: #575556;
    border: 1px solid #8F8F8F !important;
    font-size: 14px !important;
    border-radius: 3px !important;
    height: 35px !important;
    width: 100% !important;
    padding: 0.4rem !important;
   }

   @media (min-width: 1024px) {
    .basic-date-picker{
        padding: 0.625rem;
    }
}


.micro-page-date-picker{
    font-weight: bold !important;
    color: #575556;
    border: none !important;
    font-size: 14px !important;
    border-radius: 3px !important;
    height: 35px !important;
    width: 100% !important;
   }
   .micro-page-date-picker input {
       width: 100%;
   }