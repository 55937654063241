.select {
  display:flex;
  flex-direction: column;
  position:relative;
  /* width:250px; */
  height:40px;
  /* border: 1px solid #F18181; */
  border-bottom: 0px;
  border-left: 0px;
  border-right: 0px;
  
}

 
.option {
  padding:0 30px 0 10px;
  min-height:40px;
  display:flex;
  align-items:center;
  background:white;
  border:#F18181 solid 1px;
  border-top:  0px ;  
  position:absolute;
  top:0;
  width: 100%;
  pointer-events:none;
  order:2;
  z-index:1;
  transition:background .4s ease-in-out;
  box-sizing:border-box;
  overflow:hidden;
  white-space:nowrap;
  
}

.option::before{
    background: white;
}
input#opt:checked + label:hover{
    background: #F18181;
    color: white;
}
.option:hover {
  background:#F18181;
  color: white;
}

.select:focus .option {
  position:relative;
  pointer-events:all;
  
}

input#opt {
  opacity:0;
  position:absolute;
  left:-99999px;
}

input#opt:checked + label {
  order: 1;
  z-index:2;
  background:white;
   
  border-top:none;
  position:relative;
}

input#opt:checked + label:after {
  content:'';
  width: 0; 
	height: 0; 
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid #F18181;
  position:absolute;
  right:10px;
  top:calc(50% - 2.5px);
  pointer-events:none;
  z-index:3;
}

input#opt:checked + label:before {
  position:absolute;
  right:0;
  height: 40px;
  width: 40px;
  content: '';
  background:white;
}

.RegPhone>input::-webkit-outer-spin-button,
.RegPhone>input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}